import { Box, Center, Icon, IconProps, Text } from '@chakra-ui/react';
import React, { ReactElement } from 'react';

interface Props {
    svg: React.ReactNode;
    title: string;
}

export function Logo({ svg, title, ...rest }: Props & IconProps): ReactElement {
    return (
        <Box>
            <Text as={Box} fontSize="lg" fontWeight="bold" color="white">
                <Center>
                    <Icon mr={3} fontSize="30px" {...rest}>
                        {svg}
                    </Icon>
                    {title}
                </Center>
            </Text>
        </Box>
    );
}
