export class ValidationErrors {
    private errors: Form.PathValidationErrors;

    constructor(errors?: Form.PathValidationErrors) {
        this.errors = errors || {};
    }

    add(fieldPath: string, error: string): void {
        if (!(fieldPath in this.errors)) {
            this.errors[fieldPath] = [error];
        } else {
            let errs;
            if (!(errs = this.errors[fieldPath]).includes(error)) {
                errs.push(error);
            }
        }
    }

    clearPrefix(prefix: string): void {
        this.errors = Object.keys(this.errors)
            .filter((key) => !key.startsWith(prefix))
            .reduce((obj, key) => {
                return {
                    ...obj,
                    [key]: this.errors[key],
                };
            }, {});
    }

    exact(fieldPath: string): string | undefined {
        return this.errors?.[fieldPath]?.join(', ');
    }

    match(regex: RegExp): string | undefined {
        const matchingKeys = Object.keys(this.errors).filter((key) =>
            regex.test(key)
        );
        return this.combineErrors(matchingKeys);
    }

    prefix(prefix: string): string | undefined {
        const matchingKeys = Object.keys(this.errors).filter((key) =>
            key.startsWith(prefix)
        );

        if (matchingKeys.length === 1 && matchingKeys[0] === prefix) {
            return this.exact(prefix);
        }

        return this.combineErrors(matchingKeys);
    }

    clone(): ValidationErrors {
        return new ValidationErrors(this.errors);
    }

    private combineErrors(keys: string[]): string | undefined {
        if (keys.length === 0) {
            return undefined;
        }
        return keys
            .map((key) => `${key}: ${this.errors[key].join(', ')}`)
            .join('\n');
    }
}
