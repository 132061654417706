import {
    endOfMonth,
    endOfWeek,
    endOfYear,
    format,
    startOfMonth,
    startOfYear,
    subDays,
    subMonths,
    subYears,
} from 'date-fns';

export const REVENUE_GRANULARITIES: Record<string, App.RevenueGranularity> = {
    DAILY: {
        label: 'Daily',
        value: 'DAILY',
        tooltipFormat: (date: Date) => format(date, 'PPP'),
    },
    WEEKLY: {
        label: 'Weekly',
        value: 'WEEKLY',
        tooltipFormat: (date: Date) =>
            format(
                date,
                `'Week' I '('PP'-${format(
                    endOfWeek(date, { weekStartsOn: 1 }),
                    'PP'
                )})'`
            ),
    },
    MONTHLY: {
        label: 'Monthly',
        value: 'MONTHLY',
        tooltipFormat: (date: Date) => format(date, 'MMMM yyyy'),
    },
    YEARLY: {
        label: 'Yearly',
        value: 'YEARLY',
        tooltipFormat: (date: Date) => format(date, 'yyyy'),
    },
};

export interface DatePreset {
    label: string;
    from: () => number;
    to: () => number;
    defaultGranularity: App.RevenueGranularity;
    default?: boolean;
}

export const presets: DatePreset[] = [
    {
        label: 'Today',
        from: () => dayStart(new Date()),
        to: () => dayEnd(new Date()),
        defaultGranularity: REVENUE_GRANULARITIES.DAILY,
    },
    {
        label: 'Yesterday',
        from: () => dayStart(subDays(new Date(), 1)),
        to: () => dayEnd(subDays(new Date(), 1)),
        defaultGranularity: REVENUE_GRANULARITIES.DAILY,
    },
    {
        label: 'This Month',
        from: () => dayStart(startOfMonth(new Date())),
        to: () => dayEnd(endOfMonth(new Date())),
        defaultGranularity: REVENUE_GRANULARITIES.DAILY,
        default: true,
    },
    {
        label: 'Last Month',
        from: () => dayStart(startOfMonth(subMonths(new Date(), 1))),
        to: () => dayEnd(endOfMonth(subMonths(new Date(), 1))),
        defaultGranularity: REVENUE_GRANULARITIES.DAILY,
    },
    {
        label: 'This Year',
        from: () => dayStart(startOfYear(new Date())),
        to: () => dayEnd(endOfYear(new Date())),
        defaultGranularity: REVENUE_GRANULARITIES.MONTHLY,
    },
    {
        label: 'Last Year',
        from: () => dayStart(startOfYear(subYears(new Date(), 1))),
        to: () => dayEnd(endOfYear(subYears(new Date(), 1))),
        defaultGranularity: REVENUE_GRANULARITIES.MONTHLY,
    },
    {
        label: 'Last 7 Days',
        from: () => subDays(new Date(), 7).getTime(),
        to: () => Date.now(),
        defaultGranularity: REVENUE_GRANULARITIES.DAILY,
    },
    {
        label: 'Last 30 Days',
        from: () => subDays(new Date(), 30).getTime(),
        to: () => Date.now(),
        defaultGranularity: REVENUE_GRANULARITIES.DAILY,
    },
    {
        label: 'Last 90 Days',
        from: () => subDays(new Date(), 90).getTime(),
        to: () => Date.now(),
        defaultGranularity: REVENUE_GRANULARITIES.WEEKLY,
    },
    {
        label: 'Last 365 Days',
        from: () => subDays(new Date(), 365).getTime(),
        to: () => Date.now(),
        defaultGranularity: REVENUE_GRANULARITIES.MONTHLY,
    },
];

function dayStart(date: Date): number {
    return date.setHours(0, 0, 0, 0);
}

function dayEnd(date: Date): number {
    return date.setHours(23, 59, 59, 999);
}
