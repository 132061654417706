export const COOKIE_KEY = 'authToken';

export const API_ROUTES = {
    auth: {
        login: '/auth/login',
    },

    account: {
        user: '/v1/account/me',
        setup: '/v1/account/setup',
        tos: '/v1/account/tos',
    },

    organizations: {
        list: '/v1/organizations',
        select: '/v1/organizations/select',
    },

    revenue: {
        cosmetics: '/v1/revenue/cosmetics',
        mods: '/v1/revenue/mods',
        coupons: '/v1/revenue/coupons',
        total: '/v1/revenue/total',
    },
};

export enum ConfigurationType {}

export enum RevenueType {
    ESTIMATED = 'ESTIMATED',
    VALIDATED = 'VALIDATED',
}
