import { Center, LayoutProps, Spinner } from '@chakra-ui/react';
import React, { ReactElement } from 'react';

interface Props {
    height?: LayoutProps['height'];
}

export function BoxLoader({ height = '100%' }: Props): ReactElement {
    return (
        <Center height={height} backgroundColor="gray.100" borderRadius="10px">
            <Spinner />
        </Center>
    );
}
