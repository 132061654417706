import React, { useContext, useEffect, useReducer } from 'react';
import { API } from '../utils/api';
import { authContext } from './AuthStore';

// Types

export type ConfigurationAction = App.Action<ConfigurationActionType>;
export type ConfigurationStore = App.Store<
    ConfigurationState,
    ConfigurationAction
>;
export interface ConfigurationState {
    load: Configuration.ConfigurationKey[];
    loading: Configuration.ConfigurationKey[];
    data: Configuration.Configuration;
}
export enum ConfigurationActionType {
    LOAD,
    LOADING,
    SET_DATA,
    REFRESH,
}
interface ConfigurationStateProviderProps {
    getRoute: (key: string) => string;
    children: React.ReactNode;
}

// Context & Provider

const defaultState: ConfigurationState = {
    load: [],
    loading: [],
    data: {},
};
export const configurationContext = React.createContext<ConfigurationStore>({
    state: defaultState,
});
export const ConfigurationStateProvider: React.FC<
    ConfigurationStateProviderProps
> = ({ children, getRoute }: ConfigurationStateProviderProps) => {
    const [state, dispatch] = useReducer(configurationReducer, defaultState);
    const authStore = useContext(authContext);

    useEffect(() => {
        for (const t of state.load) {
            if (state.loading.includes(t)) {
                continue;
            }

            dispatch({
                type: ConfigurationActionType.LOADING,
                data: t,
            });
            API.get(
                getRoute(t),
                {
                    success: (data) => {
                        dispatch({
                            type: ConfigurationActionType.SET_DATA,
                            data: [t, data],
                        });
                    },
                },
                authStore.state.token
            );
        }
    }, [state.load, state.loading, authStore.state.token]);

    return (
        <configurationContext.Provider value={{ state, dispatch }}>
            {children}
        </configurationContext.Provider>
    );
};

// Actions & reducers

const configurationReducer = (
    state: ConfigurationState,
    action: ConfigurationAction
): ConfigurationState => {
    switch (action.type) {
        case ConfigurationActionType.LOAD:
            return {
                ...state,
                load: [
                    ...state.load,
                    ...(action.data as Configuration.ConfigurationKey[]).filter(
                        (t) =>
                            !Object.prototype.hasOwnProperty.call(state.data, t)
                    ),
                ].filter((t, i, newLoad) => newLoad.indexOf(t) === i),
            };
        case ConfigurationActionType.REFRESH:
            const toRefresh = action.data as Configuration.ConfigurationKey;
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { [toRefresh]: _, ...newData } = state.data;
            return {
                ...state,
                load: [...state.load, toRefresh],
                data: newData,
            };
        case ConfigurationActionType.LOADING:
            return {
                ...state,
                loading: [...state.loading, action.data],
            };
        case ConfigurationActionType.SET_DATA:
            const [t, d] = action.data as [
                Configuration.ConfigurationKey,
                unknown
            ];
            return {
                ...state,
                loading: state.loading.filter((x) => x !== t),
                load: state.load.filter((x) => x !== t),
                data: {
                    ...state.data,
                    [t]: d,
                },
            };
        default:
            throw new Error('Not among actions');
    }
};
