import React, { ReactElement } from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './date-picker.css';

interface Props {
    value?: Date;
    onChange: (time?: number) => void;
}

export default function DatePicker({
    value,
    onChange,
    ...props
}: Props &
    Omit<Partial<ReactDatePickerProps>, 'value' | 'onChange'>): ReactElement {
    return (
        <div className="light-theme-original">
            <ReactDatePicker
                selected={value}
                onChange={(date: Date) => onChange(date?.getTime())}
                calendarStartDay={1}
                dateFormat="Pp"
                timeFormat="HH:mm"
                className="react-datapicker__input-text"
                showPopperArrow={true}
                {...props}
            />
        </div>
    );
}
