import { Box, Icon } from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import { FiMenu, FiX } from 'react-icons/fi';

interface Props {
    toggle: () => void;
    isOpen: boolean;
}

export function ToggleNavButton({ toggle, isOpen }: Props): ReactElement {
    const icon = isOpen ? <Icon as={FiX} /> : <Icon as={FiMenu} />;
    return (
        <Box
            cursor="pointer"
            display={{ base: 'block', md: 'none' }}
            onClick={toggle}
            color="white"
            fontSize="25px"
        >
            {icon}
        </Box>
    );
}
