import { Link } from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import { Link as RouterLink } from 'react-router-dom';

interface Props {
    link?: string;
    children: React.ReactNode;
}

export function NavItem({ link, children }: Props): ReactElement | null {
    return link ? (
        <Link as={RouterLink} to={link} fontSize={16}>
            {children}
        </Link>
    ) : (
        <> {children} </>
    );
}
