import { Box, Icon, Text } from '@chakra-ui/react';
import React, { ReactElement } from 'react';

interface Props {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    icon: any;
    title: string;
    children: React.ReactChild;
}

export default function SettingBox({
    icon,
    title,
    children,
}: Props): ReactElement {
    return (
        <Box display="flex" flexDirection="column" width="100%">
            <Text fontWeight="600">
                <Icon as={icon} mr={1} />
                {title}
            </Text>
            {children}
        </Box>
    );
}
