/* eslint-disable @typescript-eslint/ban-types */
import React, { useContext, useEffect } from 'react';
import {
    ConfigurationActionType,
    configurationContext,
} from '../../stores/ConfigurationStore';
import { LoadingSpinner } from '../structure';

export interface WithConfigurationProps {
    configuration: Configuration.Configuration;
}

export const withConfiguration =
    <P extends object>(
        WrappedComponent: React.ComponentType<P & WithConfigurationProps>,
        types: Configuration.ConfigurationKey[]
    ) =>
    (props: P): React.ReactElement => {
        const configuration = useContext(configurationContext);

        useEffect(() => {
            configuration.dispatch?.({
                type: ConfigurationActionType.LOAD,
                data: types,
            });
        }, [configuration.dispatch]);

        for (const type of types) {
            if (
                !Object.prototype.hasOwnProperty.call(
                    configuration.state.data,
                    type
                )
            ) {
                return <LoadingSpinner />;
            }
        }

        return (
            <WrappedComponent
                {...(props as P)}
                configuration={
                    configuration.state.data as Configuration.Configuration
                }
            />
        );
    };
