import { createStandaloneToast, UseToastOptions } from '@chakra-ui/react';

export function errorToast(title: string, description?: string): void {
    const toast = createStandaloneToast({
        theme: window.commonConstants.customTheme,
    });

    toast(getToastOptions('error', title, description));
}

export function successToast(title: string, description?: string): void {
    const toast = createStandaloneToast({
        theme: window.commonConstants.customTheme,
    });

    toast(getToastOptions('success', title, description));
}

export function getToastOptions(
    type: UseToastOptions['status'],
    title: string,
    description?: string
): UseToastOptions {
    return {
        position: 'bottom',
        status: type,
        title,
        description,
    };
}
