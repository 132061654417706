import { Divider, List, ListIcon, ListItem, Text } from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import { IconType } from 'react-icons';

export type ListEntry =
    | {
          divider: boolean;
      }
    | {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          icon?: IconType | React.ReactNode;
          key: React.ReactNode;
          value: React.ReactNode;
      }
    | {
          element: React.ReactNode;
      };

interface Props {
    items: (ListEntry | false)[];
}

export function ItemList({ items }: Props): ReactElement {
    const renderItems = items.map((item, idx) => {
        if (!item) {
            return null;
        }

        if ('divider' in item) {
            return item.divider ? <Divider key={idx} my={3} /> : null;
        }

        if ('element' in item) {
            return item.element;
        }

        return (
            <ListItem
                key={idx}
                display="flex"
                flexDirection={['column', 'row']}
                alignItems="center"
                mb={[3, 0]}
            >
                <Text
                    display="flex"
                    alignItems="center"
                    flexGrow={1}
                    fontWeight="600"
                >
                    {React.isValidElement(item.icon) ? (
                        <>{item.icon}</>
                    ) : (
                        <ListIcon as={item.icon as IconType} />
                    )}
                    {item.key}
                </Text>

                <Text wordBreak="break-all">{item.value}</Text>
            </ListItem>
        );
    });

    return (
        <List display="flex" flexDirection="column">
            {renderItems}
        </List>
    );
}
