export function checkVersion(): void {
    const currentVersion = process.env.REACT_APP_PANEL_VERSION || 'unknown';
    const storedVersion = localStorage.getItem('panelVersion');

    if (storedVersion !== currentVersion) {
        console.info(
            `Panel version changed from ${storedVersion} to ${currentVersion}, reloading page...`
        );
        localStorage.setItem('panelVersion', currentVersion);
        window.location.reload();
    }
}
