import { minimalTimezoneSet } from 'compact-timezone-list';

export function getTimezones(): { value: App.TimezoneOffset; label: string }[] {
    return minimalTimezoneSet.map((tz) => ({
        value: {
            local: toMillis(
                getMinutesFromString(tz.offset) -
                    -1 * new Date().getTimezoneOffset()
            ),
            utc: toMillis(getMinutesFromString(tz.offset)),
        },
        label: tz.label,
    }));
}

function getMinutesFromString(hoursAndMinutes: string): number {
    const sign = hoursAndMinutes.charAt(0);
    const [hours, minutes] = hoursAndMinutes.substring(1).split(':');
    return (Number(hours) * 60 + Number(minutes)) * (sign === '-' ? -1 : 1);
}

function toMillis(minutes: number): number {
    return minutes * 60 * 1000;
}
