import Cookies from 'universal-cookie';

const cookies = new Cookies();

export function saveToken(token: string, persist: boolean): void {
    const { exp } = decodeJwt(token);
    cookies.set(window.commonConstants.cookieKey, token, {
        path: '/',
        sameSite: 'strict',
        expires: persist ? new Date(exp * 1000) : undefined,
        secure: window.location.protocol === 'https:',
    });
}

export function getToken(): string {
    return cookies.get(window.commonConstants.cookieKey);
}

export function removeToken(): void {
    cookies.remove(window.commonConstants.cookieKey);
}

export function decodeJwt(token: string): Auth.JWTPayload {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
        atob(base64)
            .split('')
            .map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join('')
    );

    return JSON.parse(jsonPayload);
}

export function validateToken(token: string): Promise<void> {
    try {
        const { exp } = decodeJwt(token);

        // Token expired
        if (exp <= Date.now() / 1000) {
            return Promise.reject();
        }
    } catch {
        return Promise.reject();
    }
    return Promise.resolve();
}
