import {
    Avatar,
    Icon,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    useColorMode,
    useColorModeValue,
} from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import { FiLogOut, FiMoon } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

interface Props {
    logout: () => void;
    name: string;
    additionalRoutes?: App.Route[];
}

export function ActionMenu({
    name,
    logout,
    additionalRoutes,
}: Props): ReactElement<Props> {
    const history = useHistory();

    const { toggleColorMode } = useColorMode();

    const textColor = useColorModeValue(
        '',
        'var(--chakra-colors-whiteAlpha-900)'
    );

    return (
        <Menu autoSelect={false} isLazy>
            <MenuButton aria-label="Settings" p={2}>
                <Avatar name={name} />
            </MenuButton>
            <MenuList color="black" p={0}>
                {additionalRoutes?.map((route, idx) => (
                    <MenuItem
                        color={textColor}
                        key={idx}
                        icon={<Icon as={route.icon} />}
                        onClick={() => history.push(route.path)}
                    >
                        {route.breadcrumb}
                    </MenuItem>
                ))}
                <MenuItem
                    icon={<Icon as={FiMoon} />}
                    color={textColor}
                    onClick={toggleColorMode}
                >
                    Toggle Dark Mode
                </MenuItem>
                <MenuItem
                    icon={<Icon as={FiLogOut} />}
                    color={textColor}
                    onClick={logout}
                >
                    Log out
                </MenuItem>
            </MenuList>
        </Menu>
    );
}
