/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { ValidationErrors } from '../utils/validation';
import { errorToast } from './toast';

type Body = Record<string, unknown> | FormData;

export const API = {
    get: <R = unknown>(
        path: string,
        responseHandler: API.ResponseHandler<R>,
        authToken?: string,
        parseJSON = true
    ): AbortController => {
        return request<R>(
            'GET',
            path,
            responseHandler,
            authToken,
            undefined,
            parseJSON
        );
    },

    getWithParams: <R = unknown>(
        path: string,
        queryParams: { [key: string]: string | number | boolean | undefined },
        responseHandler: API.ResponseHandler<R>,
        authToken?: string
    ): AbortController => {
        const qs = Object.keys(queryParams)
            .filter((k) => queryParams[k] !== undefined)
            .map(
                (k) =>
                    encodeURIComponent(k) +
                    '=' +
                    encodeURIComponent(queryParams[k]!)
            )
            .join('&');
        return API.get<R>(
            path + (qs.length > 0 ? `?${qs}` : ''),
            responseHandler,
            authToken
        );
    },

    post: <R = unknown, B = Body>(
        path: string,
        data: B,
        responseHandler: API.ResponseHandler<R>,
        authToken?: string
    ): AbortController => {
        return request<R, B>('POST', path, responseHandler, authToken, data);
    },

    put: <R = unknown, B = Body>(
        path: string,
        data: B,
        responseHandler: API.ResponseHandler<R>,
        authToken?: string
    ): AbortController => {
        return request<R, B>('PUT', path, responseHandler, authToken, data);
    },

    delete: <R = unknown>(
        path: string,
        responseHandler: API.ResponseHandler<R>,
        authToken?: string
    ): AbortController => {
        return request<R>('DELETE', path, responseHandler, authToken);
    },
};

function request<R, B = Body>(
    method: string,
    path: string,
    responseHandler: API.ResponseHandler<R>,
    authToken?: string,
    data?: B,
    parseJSON = true
): AbortController {
    const controller = new AbortController();
    const { signal } = controller;

    fetch(`${window.commonConstants.apiUrl}${path}`, {
        signal,
        body: JSON.stringify(data),
        method,
        headers: authToken
            ? {
                  Authorization: `Bearer ${authToken}`,
              }
            : {},
    })
        .then(async (res) => {
            // Success
            if (res.status >= 200 && res.status < 300) {
                let data;
                if (parseJSON) {
                    try {
                        data = await res.json();
                    } catch {
                        // Nothing to do here
                    }
                }

                return responseHandler.success(data, res);
            }

            // Bad request/validation error
            if (res.status === 400) {
                const err: API.BadRequestError = await res.json();

                if (
                    err.type === 'ValidationException' &&
                    responseHandler.validation
                ) {
                    const errors = (err as API.ValidationError).errors;
                    // FIXME more elegantly
                    return responseHandler.validation({
                        path: new ValidationErrors(errors?.path),
                        query: new ValidationErrors(errors?.query),
                        cookie: new ValidationErrors(errors?.cookie),
                        header: new ValidationErrors(errors?.header),
                        body: new ValidationErrors(errors?.body),
                    });
                }
                return (
                    responseHandler.badRequest ||
                    defaultResponseHandler.badRequest!
                )(err as API.ValidationError);
            }

            // Unauthorized error
            if (res.status === 401) {
                return (
                    responseHandler.unAuthorized ||
                    defaultResponseHandler.unAuthorized!
                )();
            }

            // Not found error
            if (res.status === 404) {
                return (
                    responseHandler.notFound || defaultResponseHandler.notFound!
                )();
            }

            // Client error (generic)
            if (
                res.status >= 400 &&
                res.status < 500 &&
                responseHandler.clientError
            ) {
                return responseHandler.clientError(res);
            }

            // Server error (generic)
            if (
                res.status >= 500 &&
                res.status < 600 &&
                responseHandler.serverError
            ) {
                return responseHandler.serverError(res);
            }

            // Catchall
            return (
                responseHandler.internalError ||
                defaultResponseHandler.internalError!
            )();
        })
        .catch((e) => {
            // Request was aborted
            if (e.name === 'AbortError') return;
            (
                responseHandler.internalError ||
                defaultResponseHandler.internalError!
            )();
        });
    return controller;
}

// Default response handler
const defaultResponseHandler: API.ResponseHandler<null> = {
    // Unused
    success: () => undefined,
    validation: () => {
        // Nothing
    },

    badRequest: (err: API.BadRequestError) => {
        errorToast('Invalid request', err.message);
    },
    unAuthorized: () => {
        errorToast(
            'Unauthorized',
            'You are not permitted to access this resource'
        );
    },
    notFound: () => {
        errorToast('404 not found', 'This resource cannot be found');
    },
    internalError: () => {
        errorToast('Internal error', 'Please try again later');
    },
};
