import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Collapse, Icon, useDisclosure } from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import { NavLinkProps, NavLinks } from './NavLinks';

interface Props extends NavLinkProps {
    parent: App.Route;
    routes: { [key: string]: App.Route };
    routeText: React.ReactNode;
}

export function SubMenu({
    parent,
    routes,
    routeText,
    ...props
}: Props): ReactElement {
    const { isOpen, onToggle } = useDisclosure({
        defaultIsOpen: [
            parent.path,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            ...Object.entries(routes).map(([_id, route]) => route.path),
        ].includes(window.location.pathname),
    });

    return (
        <>
            <Box
                onClick={(e) => {
                    e.preventDefault();
                    onToggle();
                }}
                cursor="pointer"
            >
                {routeText}
                <Box float="right">
                    <Icon as={isOpen ? ChevronDownIcon : ChevronRightIcon} />
                </Box>
            </Box>

            <Collapse in={isOpen} animateOpacity>
                <Box mt={2} borderRadius="15px" pl={4}>
                    <NavLinks {...props} routes={routes} subLevel />
                </Box>
            </Collapse>
        </>
    );
}
