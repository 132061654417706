import { Center, Text } from '@chakra-ui/react';
import React, { ReactElement } from 'react';

interface Props {
    version: string;
}

export function VersionDisplay({ version }: Props): ReactElement {
    return (
        <Center color="gray.500" pb={5}>
            <Text>Panel Version: {version}</Text>
        </Center>
    );
}
