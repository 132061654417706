import { Box, Icon, Stack } from '@chakra-ui/react';
import React, { ReactElement, useContext } from 'react';
import { authContext } from '../../../stores';
import { NavItem } from './NavItem';
import { SubMenu } from './SubMenu';
import { VersionDisplay } from './VersionDisplay';

export interface NavLinkProps {
    panelVersion: string;
    checkAccess: (route: App.Route, user?: User.BaseUser) => boolean;
    routes: { [key: string]: App.Route };
    subLevel?: boolean;
}

export function NavLinks(props: NavLinkProps): ReactElement {
    const { state } = useContext(authContext);
    const { panelVersion, checkAccess, routes, subLevel } = props;

    const navItems = Object.entries(routes).map(([id, route]) => {
        // Don't show nav item if user doesn't have access to it
        if (!checkAccess(route, state.user)) {
            return null;
        }

        const active =
            route.type === 'page' && route.path === window.location.pathname;
        const routeText = (
            <>
                {route.icon && <Icon mr={1} as={route.icon} />}{' '}
                {route.breadcrumb}
            </>
        );
        return route.nav ? (
            <Box
                key={id}
                color="white"
                fontWeight="500"
                backgroundColor={active ? 'gray.600' : undefined}
                padding="10px"
            >
                {route.type === 'page' && (
                    <NavItem key={id} link={route.path}>
                        {routeText}
                    </NavItem>
                )}

                {route.type === 'submenu' && (
                    <SubMenu
                        {...props}
                        parent={route}
                        routes={route.subMenu}
                        routeText={routeText}
                    />
                )}
            </Box>
        ) : null;
    });

    return (
        <Stack spacing={0} align="left" direction="column" flexGrow={1}>
            <Box flexGrow={1}>{navItems}</Box>
            {!subLevel && <VersionDisplay version={panelVersion} />}
        </Stack>
    );
}
