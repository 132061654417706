import { Box, BoxProps } from '@chakra-ui/react';
import React, { ReactElement } from 'react';

interface Props {
    children: React.ReactNode;
}

export function BorderBox({
    children,
    ...props
}: Props & BoxProps): ReactElement {
    return (
        <Box padding="3" border="1px solid #ccc" borderRadius="16px" {...props}>
            {children}
        </Box>
    );
}
