import React, { useEffect, useReducer } from 'react';
import { API } from '../utils/api';
import { removeToken, saveToken, validateToken } from '../utils/token';

// Types

export type AuthAction = App.Action<AuthActionType>;
export type AuthStore = App.Store<AuthState, AuthAction>;
export interface AuthState {
    isLoading: boolean;
    user?: User.BaseUser;
    token?: string;
}
export enum AuthActionType {
    LOGIN,
    SET_USER,
    LOGOUT,
}
interface AuthStateProviderProps {
    apiUserRoute: string;
    initialState: Partial<AuthState>;
    children: React.ReactNode;
}

// Context & Provider

const defaultState: AuthState = {
    isLoading: true,
};
export const authContext = React.createContext<AuthStore>({
    state: defaultState,
});
export const AuthStateProvider: React.FC<AuthStateProviderProps> = ({
    children,
    apiUserRoute,
    initialState,
}: AuthStateProviderProps) => {
    const [state, dispatch] = useReducer(authReducer, {
        ...defaultState,
        ...initialState,
    });

    useEffect(() => {
        let controller: AbortController;
        if (state.token && !state.user) {
            validateToken(state.token).then(
                () =>
                    (controller = API.get<User.BaseUser>(
                        apiUserRoute,
                        {
                            success: (user) => {
                                dispatch?.({
                                    type: AuthActionType.SET_USER,
                                    data: user,
                                });
                            },

                            unAuthorized: () => {
                                dispatch?.({
                                    type: AuthActionType.LOGOUT,
                                });
                            },
                        },
                        state.token
                    ))
            );
        } else if (!state.token) {
            dispatch?.({
                type: AuthActionType.LOGOUT,
            });
        }

        return () => {
            controller?.abort();
        };
    }, [state.token, state.user]);

    return (
        <authContext.Provider value={{ state, dispatch }}>
            {children}
        </authContext.Provider>
    );
};

// Actions & reducers

const authReducer = (state: AuthState, action: AuthAction): AuthState => {
    switch (action.type) {
        case AuthActionType.LOGIN:
            const token = action.data.token as string;
            const keepLoggedIn = action.data.keepLoggedIn;

            saveToken(token, keepLoggedIn);

            return {
                ...state,
                token,
                isLoading: true,
            };

        case AuthActionType.SET_USER:
            const user = action.data as User.BaseUser;
            return {
                ...state,
                isLoading: false,
                user,
            };

        case AuthActionType.LOGOUT:
            removeToken();

            return {
                ...state,
                isLoading: false,
                user: undefined,
                token: undefined,
            };
        default:
            throw new Error('Not among actions');
    }
};
