import { Box, Button, Center, Divider, Icon, Stack } from '@chakra-ui/react';
import React, { ReactElement, useEffect, useState } from 'react';
import { ReactDatePickerProps } from 'react-datepicker';
import {
    FiActivity,
    FiArrowRight,
    FiCalendar,
    FiGlobe,
    FiSliders,
} from 'react-icons/fi';
import { errorToast } from '../../../utils';
import { getTimezones } from '../../../utils/timezones';
import { Select } from '../ChakraReactSelect';
import DatePicker from '../DatePicker';
import { DatePreset, presets, REVENUE_GRANULARITIES } from './constants';
import SettingBox from './SettingBox';

const DATE_FORMAT = 'yyyy-MM-dd HH:mm:ss';

interface Props {
    load: (range: DateRange) => void;
    enablePresets?: boolean;
    enableGranularity?: boolean;
    enableTimezoneSelector?: boolean;
    adjustTimezoneOffset?: boolean;
    forceTimezoneOffset?: App.TimezoneOffset;

    datePickerProps?: Omit<Partial<ReactDatePickerProps>, 'onChange' | 'value'>;

    additionalSettings?: React.ReactNode;
}

export interface DateRange {
    from: number;
    to: number;
    granularity?: App.RevenueGranularity;
    utcOffset?: number;
    timezoneOffset?: number;
}

interface OptionProps<D> {
    value: D;
}

export function DateRangePicker({
    load: parentLoad,
    enablePresets,
    enableGranularity,
    enableTimezoneSelector,
    adjustTimezoneOffset,
    forceTimezoneOffset,
    datePickerProps,
    additionalSettings,
}: Props): ReactElement {
    const [from, setFrom] = useState<number | undefined>();
    const [to, setTo] = useState<number | undefined>();

    const [preset, setPreset] = useState<DatePreset | undefined>(
        enablePresets ? presets.find((p) => p.default) : undefined
    );
    const [granularity, setGranularity] = useState<
        App.RevenueGranularity | undefined
    >(enableGranularity ? REVENUE_GRANULARITIES.WEEKLY : undefined);
    const [timezoneOffset, setTimezoneOffset] = useState<App.TimezoneOffset>(
        (getTimezones().find((tz) => tz.value.local === 0) || getTimezones()[0])
            .value
    );

    const submit = () => {
        if (!from || !to) {
            errorToast('Please select a date range');
            return;
        }

        load(from, to, granularity);
    };

    const load = (
        from: number,
        to: number,
        granularity?: App.RevenueGranularity
    ) => {
        const tzOffset = forceTimezoneOffset
            ? forceTimezoneOffset
            : adjustTimezoneOffset
            ? timezoneOffset
            : undefined;
        const offset = tzOffset ? tzOffset.local : 0;
        const dateRange: DateRange = {
            from: from - offset,
            to: to - offset,
        };

        if (enableGranularity && granularity) {
            dateRange.granularity = granularity;
        }
        if (tzOffset) {
            dateRange.timezoneOffset = tzOffset.local;
            dateRange.utcOffset = tzOffset.utc;
        }

        parentLoad(dateRange);
    };

    useEffect(() => {
        if (preset) {
            setFrom(preset.from());
            setTo(preset.to());
            setGranularity(preset.defaultGranularity);
            load(preset.from(), preset.to(), preset.defaultGranularity);
        }
    }, [preset]);

    const currentFrom = from ? new Date(from) : undefined;
    const currentTo = to ? new Date(to) : undefined;
    return (
        <>
            <Stack direction={['column', 'column', 'column', 'row']}>
                {enableTimezoneSelector && (
                    <SettingBox icon={FiGlobe} title="Timezone">
                        <Select
                            options={getTimezones()}
                            value={{
                                label: getTimezones().find(
                                    (tz) =>
                                        tz.value.local === timezoneOffset.local
                                )?.label,
                                value: timezoneOffset,
                            }}
                            onChange={(e: OptionProps<App.TimezoneOffset>) => {
                                if (!e) {
                                    return;
                                }
                                setTimezoneOffset(e.value);
                            }}
                        />
                    </SettingBox>
                )}
                {enablePresets && (
                    <SettingBox icon={FiSliders} title="Date Presets">
                        <Select
                            placeholder="Custom"
                            options={presets.map((p) => ({
                                label: p.label,
                                value: p,
                            }))}
                            value={preset || null}
                            onChange={(e: OptionProps<DatePreset>) => {
                                if (!e) {
                                    return;
                                }
                                setPreset(e.value);
                            }}
                        />
                    </SettingBox>
                )}
                <SettingBox icon={FiCalendar} title="From Date">
                    <DatePicker
                        dateFormat={DATE_FORMAT}
                        selected={currentFrom}
                        onChange={(e) => {
                            setPreset(undefined);
                            setFrom(e);
                        }}
                        selectsStart
                        isClearable
                        showTimeSelect
                        startDate={currentFrom}
                        endDate={currentTo}
                        {...datePickerProps}
                    />
                </SettingBox>
                <Box
                    alignSelf="flex-end"
                    height="26px"
                    display={['none', 'none', 'none', 'inherit']}
                >
                    <Icon as={FiArrowRight} />
                </Box>
                <SettingBox icon={FiCalendar} title="To Date">
                    <DatePicker
                        dateFormat={DATE_FORMAT}
                        selected={currentTo}
                        onChange={(e) => {
                            setPreset(undefined);
                            setTo(e);
                        }}
                        selectsEnd
                        isClearable
                        showTimeSelect
                        startDate={currentFrom}
                        endDate={currentTo}
                        minDate={currentFrom}
                        {...datePickerProps}
                    />
                </SettingBox>
                {enableGranularity && (
                    <SettingBox icon={FiActivity} title="Graph Resolution">
                        <Select
                            options={Object.values(REVENUE_GRANULARITIES)}
                            value={granularity}
                            onChange={(e: App.RevenueGranularity) => {
                                setPreset(undefined);
                                setGranularity(
                                    Object.values(REVENUE_GRANULARITIES).find(
                                        (g: App.RevenueGranularity) =>
                                            g.value === e.value
                                    ) || REVENUE_GRANULARITIES.DAILY
                                );
                            }}
                        />
                    </SettingBox>
                )}
                {additionalSettings}
            </Stack>
            <Box py={7} my={4}>
                <Divider position="relative" top="50%" />
                <Center position="relative" top="50%">
                    <Button
                        position="absolute"
                        colorScheme="green"
                        size="md"
                        variant="outline"
                        backgroundColor="white"
                        minWidth="50%"
                        onClick={submit}
                    >
                        Load
                    </Button>
                </Center>
            </Box>
        </>
    );
}

export * from './SettingBox';
