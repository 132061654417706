import { authContext } from 'common/stores';
import { API } from 'common/utils';
import React, { useContext, useEffect, useReducer } from 'react';
import { API_ROUTES } from 'utils/constants';

// Types

export type OrgAction = App.Action<OrgActionType>;
export type OrgStore = App.Store<OrgState, OrgAction>;
export interface OrgState {
    isLoading: boolean;
    organizations?: Organization.Organization[];
    current?: Organization.Organization;
}
export enum OrgActionType {
    SET_ORGANIZATIONS,
}
interface OrgStateProviderProps {
    initialState: Partial<OrgState>;
    children: React.ReactNode;
}

// Context & Provider

const defaultState: OrgState = {
    isLoading: true,
};
export const orgContext = React.createContext<OrgStore>({
    state: defaultState,
});
export const OrgStateProvider: React.FC<OrgStateProviderProps> = ({
    children,
    initialState,
}: OrgStateProviderProps) => {
    const authStore = useContext(authContext);

    const [state, dispatch] = useReducer(orgReducer, {
        ...defaultState,
        ...initialState,
    });

    useEffect(() => {
        let controller: AbortController;

        if (
            authStore.state.user &&
            (authStore.state.user as User.PartnerUser).setup &&
            (authStore.state.user as User.PartnerUser).terms
        ) {
            controller = API.get<Organization.Organization[]>(
                API_ROUTES.organizations.list,
                {
                    success: (orgs) => {
                        let selectedOrg =
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            (authStore.state.user! as User.PartnerUser)
                                .selected_organization;
                        let current = orgs?.find(
                            (org) => org._id === selectedOrg
                        );

                        if (!selectedOrg || !current) {
                            current = orgs?.[0];
                            selectedOrg = current?._id;

                            // Store the selected org
                            API.post(
                                API_ROUTES.organizations.select,
                                {
                                    organization: current?._id,
                                } as never,
                                {
                                    success: () => {
                                        // Ignored
                                    },
                                },
                                authStore.state.token
                            );
                        }
                        dispatch?.({
                            type: OrgActionType.SET_ORGANIZATIONS,
                            data: [orgs, current],
                        });
                    },
                },
                authStore.state.token
            );
        }

        return () => {
            controller?.abort();
        };
    }, [authStore.state.user, authStore.state.token]);

    return (
        <orgContext.Provider value={{ state, dispatch }}>
            {children}
        </orgContext.Provider>
    );
};

// Actions & reducers

const orgReducer = (state: OrgState, action: OrgAction): OrgState => {
    switch (action.type) {
        case OrgActionType.SET_ORGANIZATIONS:
            const [organizations, current] = action.data as [
                Organization.Organization[],
                Organization.Organization
            ];

            return {
                ...state,
                isLoading: false,
                organizations,
                current,
            };
        default:
            throw new Error('Not among actions');
    }
};
