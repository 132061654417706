import React, { ReactElement, useContext } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { authContext } from '../../stores';
import { checkConditions } from '../../utils';

interface Props {
    route: App.BaseRoute;
}

export function ConditionRoute({
    route,
    children,
    ...rest
}: Props & RouteProps): ReactElement | null {
    const { state } = useContext(authContext);

    const result = checkConditions(route, state.user);
    if (!result.success) {
        return result.renderComponent || null;
    }

    return <Route {...rest}>{children}</Route>;
}
