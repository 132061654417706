import { Center, Flex, Spinner } from '@chakra-ui/react';
import React, { ReactElement } from 'react';

export function LoadingSpinner(): ReactElement {
    return (
        <Center minHeight="100vh" w="100vw">
            <Flex width="full" align="center" justifyContent="center" m={3}>
                <Spinner size="xl" />
            </Flex>
        </Center>
    );
}
