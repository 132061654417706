export function checkConditions(
    route: App.BaseRoute,
    user?: User.BaseUser
): App.ConditionCheckResult {
    for (const condition of route.conditions || []) {
        const result = condition(route, user);
        if (!result.success) {
            return result;
        }
    }
    return { success: true };
}
