import {
    Button,
    Icon,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
    Portal,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

export interface SortingOption {
    field: string;
    label: string;
    defaultDirection?: SortDirection;
}

export enum SortDirection {
    ASC = 'ASC',
    DSC = 'DSC',
}

interface SortingConfig {
    sortField: string;
    sortDir: string;
}

type UseSortingProps = {
    sortingOptions: SortingOption[];
    defaultOption?: SortingOption;
};

type UseSortingHook = {
    sortingButton: React.ReactElement;
    sortingConfig?: SortingConfig;
};

export function useSorting({
    sortingOptions,
    defaultOption,
}: UseSortingProps): UseSortingHook {
    const [sortingConfig, setSortingConfig] = useState<
        SortingConfig | undefined
    >();

    const sortingButton: React.ReactElement = React.useMemo(() => {
        const sc =
            sortingConfig ||
            (defaultOption && {
                sortField: defaultOption.field,
                sortDir: defaultOption.defaultDirection || SortDirection.ASC,
            });
        const dirIcon = (dir: string) =>
            dir === SortDirection.ASC ? FiChevronUp : FiChevronDown;

        return sortingOptions.length > 0 && sc ? (
            <Menu isLazy>
                <MenuButton
                    as={Button}
                    colorScheme="orange"
                    leftIcon={<Icon as={dirIcon(sc.sortDir)} />}
                    aria-label="Sorting"
                >
                    {
                        sortingOptions.find((so) => so.field === sc.sortField)
                            ?.label
                    }
                </MenuButton>
                <Portal>
                    <MenuList>
                        {sortingOptions
                            .map((option, idx) =>
                                Object.keys(SortDirection).map((dir) => ({
                                    ...option,
                                    dir,
                                    idx,
                                }))
                            )
                            .flatMap((options) => {
                                const elems = options.map((option) => (
                                    <MenuItem
                                        fontWeight={
                                            option.field === sc.sortField &&
                                            option.dir === sc.sortDir
                                                ? 'bold'
                                                : undefined
                                        }
                                        key={option.field + option.dir}
                                        onClick={() =>
                                            setSortingConfig({
                                                sortField: option.field,
                                                sortDir: option.dir,
                                            })
                                        }
                                    >
                                        {option.label}
                                        <Icon ml={1} as={dirIcon(option.dir)} />
                                    </MenuItem>
                                ));

                                if (
                                    options[0].idx !==
                                    sortingOptions.length - 1
                                ) {
                                    elems.push(
                                        <MenuDivider
                                            key={options[0].field + 'div'}
                                        />
                                    );
                                }

                                return elems;
                            })}
                    </MenuList>
                </Portal>
            </Menu>
        ) : (
            <></>
        );
    }, [setSortingConfig, defaultOption, sortingOptions, sortingConfig]);

    return { sortingButton, sortingConfig };
}
