import { ConditionRoute, LoadingSpinner } from 'common/components';
import { authContext, AuthState } from 'common/stores';
import React, { lazy, Suspense, useContext } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ROUTES } from './utils/routes';

export default function MainApp(): React.ReactElement {
    const authStore = useContext(authContext);

    // Check for auth state loading
    if (authStore.state.isLoading) {
        return <LoadingSpinner />;
    }

    // Assemble routes
    const routes = getRoutes(ROUTES, authStore.state);

    const ErrorPage = lazy(() =>
        import('common/pages/ErrorPage').then((module) => ({
            default: module.ErrorPage,
        }))
    );
    return (
        <Router>
            <ErrorBoundary fallback={<ErrorPage code={500} />}>
                <Suspense fallback={<LoadingSpinner />}>
                    <Switch>
                        {routes}

                        <Route>
                            <ErrorPage code={404} />
                        </Route>
                    </Switch>
                </Suspense>
            </ErrorBoundary>
        </Router>
    );
}

function getRoutes(
    routes: { [key: string]: App.Route },
    state: AuthState
): React.ReactNode[] {
    return Object.entries(routes).map(([id, route]) => {
        const UsedRoute = route.conditions ? ConditionRoute : Route;

        const routes: React.ReactNode[] =
            route.type === 'page'
                ? [
                      <UsedRoute
                          key={id}
                          path={route.path}
                          exact={route.exact}
                          route={route}
                      >
                          <route.page {...route.pageProps} />
                      </UsedRoute>,
                  ]
                : [];

        if (route.type === 'submenu') {
            routes.push(...getRoutes(route.subMenu, state));
        }

        return routes;
    });
}
